exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-book-appointment-tsx": () => import("./../../../src/pages/book-appointment.tsx" /* webpackChunkName: "component---src-pages-book-appointment-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-specialties-tsx": () => import("./../../../src/pages/specialties.tsx" /* webpackChunkName: "component---src-pages-specialties-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-services-page-tsx": () => import("./../../../src/templates/services-page.tsx" /* webpackChunkName: "component---src-templates-services-page-tsx" */),
  "component---src-templates-specialty-page-tsx": () => import("./../../../src/templates/specialty-page.tsx" /* webpackChunkName: "component---src-templates-specialty-page-tsx" */)
}

